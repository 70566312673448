/**********  CUSTOM ***************/
.main_txt{
	padding-top: 9%;
    padding-left: 6%;
	max-width: 800px;
	@media screen and (max-width: 991px) {
		padding-top: 0;
	}
}
.navbar-nav{
	.nav-item{
		@media screen and (max-width: 991px) {
			margin-top: 20px;
		}
	}
}
.callback{
	border-radius: 300px;
    background: #fff;
    color: #000;
    position: relative;
    padding: 12px 20px 12px 45px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
	
	span{
		font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
	}
	
	&:before{
		position: absolute;
		left: 1%;
		font-size: 23px;
		color: #ffffff;
		top: 50%;
		transform: translateY(-50%);
		line-height: 100%;
		background: #f05f40;
		width: 34px;
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}
	&:after{
		content: '';
		position: absolute;
		width: 0;
		height: 2px;
		background: #f05f40;
		left: 20px;
		bottom: 3px;
		transition: .5s;
	}
	&:hover{
		text-decoration:none;
		color:#000;
	}
	&:hover:after{
		width: 76%;
	}
}

.img{
	width: 100%;
}

#bl_1{
	background: #98ced9;
	.text{
		color: #fff;
		p{
			margin-bottom: 8px;
		}
	}
}

#bl_3{
	background: #bcdae8;
	.text{
		color:#fff;
	}
}
#bl_4{
	background: #8088a0;
	.list{
		.item{
			color:#fff;
		}
	}
}
#bl_5{
	.list{
		.item{
			&.first{
				font-weight: 600;
			}
		}
	}
}
#about{
	display:none;
}
.text{
	font-size: 18px;
}
.mfp-content{
	max-width: 500px;
}

#callback_modal{
	display:flex;
	background: #fff;
	padding: 30px 20px 20px 20px;
	border-radius: 10px;
	
	#callback_form{
		
		.box_field{
			margin-bottom: 8px;
			
			.cust_inp{
				width: 100%;
				padding: 8px 10px;
				font-size: 16px;
				color: #333;
				border: 1px solid #a9a9a9;
			}
			&.policy{
				line-height: 100%;
			}
			.txt_privacy{
				font-size: 12px;
			}			
			&:focus{
				outline:none;
			}
			&.buttons{
				align-items: center;
				display: flex;
				flex-direction: column;
				.cust_subm{
					margin-bottom: 8px;
					width:max-content;
					border-radius: 300px;
					background: #fff;
					border: 1px solid #f05f40;
					color: #f05f40;
					padding: 10px 70px;
					cursor: pointer;
					transition:.3s;
					&:hover{
						text-decoration:none;
						background:#f05f40;
						color:#fff;
					}
				}
			}
			
			.result_send{
				color: green;
				margin: 0;
				text-align: center;
				font-size: 12px;
				&.err{
					color: red;
				}
			}
		}
	}
	
	.mfp-close{
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
	
#contact{
	.logo_box{
		padding-bottom: 25px;
	}
	.box_callback{
		margin-bottom: 25px;
		
		.callback_footer{
			border: 2px solid #f05f40;
			border-radius: 300px;
			padding: 15px 60px;
			display: block;
			width: max-content;
			margin: 0 auto;
			text-transform: uppercase;
			font-weight: 600;
			transition:.3s;
			
			&:hover{
				text-decoration:none;
				background:#f05f40;
				color:#fff;
			}
		}
	}
}
	

		
footer{
	background: #333;
	.copyrite{
		padding: 10px 0;
		font-size: 12px;
		color:#fff;
	}
}